<template>
  <PageContainer>
    <PageTitle>
        <div>
          {{ trans('PAGE_TITLE:NewContacts') }}
        </div>
        <BadgeFrame v-if="userData && userData.isBanned">
          <Badge tagName="span" variant="badgeError">
            {{ trans('LABEL:AccountBlocked') }}
          </Badge>
        </BadgeFrame>
    </PageTitle>
    <PageContentWithSidebar>
      <!-- Filter sidebar START -->
      <Sidebar>
        <t-card class="w-full lg:w-sidebar lg:h-search-container  overflow-y-scroll">
          <div class="block lg:hidden bg-white w-full flex flex-row justify-between">
            <Button
              @clicked="changeNav('favourites')"
              :iconActive="nav.favourites"
              variant="icon"
              icon="funnel"
            ></Button>
            <Button
              @clicked="changeNav('filters')"
              :iconActive="nav.filters"
              variant="icon"
              icon="filters"
            ></Button>
            <Button
              @clicked="changeNav('remembered')"
              :iconActive="nav.remembered"
              variant="icon"
              icon="starFilled"
            ></Button>
          </div>
          <SidebarSectionSeparatorLine class="block lg:hidden"/>
          <SearchSidebar :current-nav="nav" />
        </t-card>
      </Sidebar>
			<!--	Loading spinner    -->
      <div v-if="!isMobile || (isMobile && !nav.remembered)">
        <div v-if="usersLoading">
          <Loader class="w-full mt-3 lg:mt-0 lg:w-main-container lg:h-search-container"
            :class="getHeightOfScreenWidth(1)"
          />
        </div>
        <!-- Search results START-->
        <div v-else class="flex flex-col lg:mb-6 w-full space-y-3 items-center">
          <t-card class="w-full flex flex-col lg:w-main-container lg:h-search-container"
            v-if="userData"
          >
            <!-- User not allowed to see search results -->
            <div v-if="!usersLoading && userData.isBanned" class="w-full flex justify-center">
              <div class="w-3/4 grid justify-items-center leading-normal gap-3 mt-3">
                <base-icon name="newContactsFilled" :width="120" :height="120" color="fill-grayedLight" />
                <template v-if="userData.isBanned">
                  <Hint variant="Error" :large="true" class="w-min whitespace-nowrap">
                    {{ trans('MESSAGE:SearchIsBlockedBecauseOfBan1') }}<br/>
                    {{ trans('MESSAGE:SearchIsBlockedBecauseOfBan2') }}
                  </Hint>
                </template>
              </div>
            </div>
            <div class="flex flex-col gap-3 lg:gap-6 lg:grid lg:grid-cols-3"
              :class="{
                'lg:grid-rows-3': !usersLoading && !isInitialSearch && !userData.isBanned,
                'lg:grid-rows-1': !usersLoading && isInitialSearch && !userData.isBanned,
              }"
            >
              <!-- User allowed to see search results -->
              <template v-if="!usersLoading && !userData.isBanned">
                <!-- Matching profiles -->
                <div :class="{'order-1':!isMobile, 'order-2':isMobile}"
                  class="w-full lg:w-auto col-span-1"
                  v-for="(user, index) in usersData" :key="index"
                >
                  <UserCard @clicked="showProfileModal(user.username)"
                    :is-remembered="userData.rememberedNames.includes(user.username)"
                    :user-data="user"
                    :index="index"
                  />
                </div>
                <!-- Hinty -->
                <div v-if="isInitialSearch || usersData.length === 0"
                  :class="{'order-2':!isMobile, 'order-1':isMobile}"
                  class="col-span-full flex flex-col justify-center items-center"
                >
                  <template v-if="isInitialSearch">
                    <Hint variant="Success" :large="true" class="text-center lg:w-min lg:whitespace-nowrap flex flex-col items-center">
                      <span>
                        <template v-if="configData.searchStartingScreenContent === 'FAKE'">
                          {{ trans('LABEL:StartSearchFakeProfiles') }}
                        </template>
                        <template v-else>
                          {{ trans('LABEL:StartSearchRandomProfiles') }}
                        </template>
                      </span>
                      <span class="font-semibold">
                        {{ trans('LABEL:StartSearchChooseFilters') }}
                      </span>
                    </Hint>
                  </template>
                  <!-- No results -->
                  <template v-else>
                    <template v-if="usersData.length === 0">
                      <div class="w-full justify-center items-center flex flex-col gap-3 ">
                        <base-icon name="newContactsFilled" :width="120" :height="120" color="fill-grayedLight" />
                        <Hint variant="Info" :large="true" class="text-center lg:w-min lg:whitespace-nowrap">
                          {{ trans('MESSAGE:NoQueryUsersResult') }}
                        </Hint>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>
          </t-card>
          <div class="mt-auto" v-if="pagination.lastPage !== 1 && !this.isInitialSearch">
            <t-pagination
              :total-items="pagination.total"
              :per-page="pagination.perPage"
              :limit="5"
              v-model="currentPage"
              @change="next"
              :disabled="usersLoading"
            ></t-pagination>
          </div>
        </div>
      </div>
      <!-- Search results END -->
    </PageContentWithSidebar>
	  <Modal style="right: 300px;" hide-footer variant="profile" v-if="isShowProfileModal" :show-modal="isShowProfileModal" @clicked="isShowProfileModal = false">
		  <template #content>
			  <ProfileGuest :username="profileUsername"/>
		  </template>
	  </Modal>
  </PageContainer>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Badge from "@/utils/crud/components/Badge";
import BadgeFrame from "@/utils/crud/components/BadgeFrame";
import Button from "@/utils/crud/components/Button";
import Loader from "@/views/Loader";
import PageContainer from "@/layouts/components/pages/PageContainer";
import PageTitle from "@/layouts/components/pages/PageTitle";
import SearchSidebar from "@/views/content/filters/SearchSidebar";
import UserCard from "@/views/content/components/UserCard";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import { ref } from '@vue/composition-api'
import {goToSpecificRoute, getHeightOfScreenWidth} from "@/common/helpers/utils";
import Modal from "@/utils/modal/Modal.vue";
import ProfileGuest from "@/views/content/profile/ProfileGuest.vue";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Hint from "@/views/content/components/Hint";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"
import PageContentWithSidebar from "@/layouts/components/pages/PageContentWithSidebar"
import Sidebar from "@/layouts/components/pages/Sidebar"

export default {
  name: "SearchView",
  components: {
    ProfileGuest,
    Modal,
    Badge,
    BadgeFrame,
    Button,
    BaseIcon,
    Hint,
    Loader,
    PageTitle,
    PageContainer,
    SearchSidebar,
    SidebarSectionSeparatorLine,
    UserCard,
    PageContentWithSidebar,
    Sidebar
  },
  data() {
    return {
      goToSpecificRoute: goToSpecificRoute,
      getHeightOfScreenWidth: getHeightOfScreenWidth,
      showFilters: true,
      nav: {
        favourites: true,
        filters: false,
        remembered: false,
      },
	    prevRoute: null,
	    isShowProfileModal: false,
	    profileUsername: false,
    }
  },
  computed: {
    ...mapGetters('users', ['usersData', 'pagination', 'usersLoading', 'userFilters', 'favFilters', 'isInitialSearch', 'lastPage', 'backFromNewContacts']),
    ...mapGetters('lang', ['langLocale', 'configData']),
    ...mapGetters('dictionaries', ['categoriesData']),
	  ...mapGetters('account', ['userData']),
	  ...mapGetters('utils', ['currentSearchNav']),

    isMobile() {
      return checkIsMobileDevice();
    }
  },
  watch: {
    langLocale() {
      this.fetchUsersData();
    },
    userFilters: {
      deep: true,
      handler() {
        if(!this.isInitialSearch) {
          this.fetchUsersData();
					if(this.backFromNewContacts) {
						this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1;
					} else {
						this.currentPage = 1;
						this.$router.push({name: 'search'}).catch(err => err)
					}
					this.setBackFromNewContacts(false);
        }
      }
    },
	  isInitialSearch: {
			immediate: true,
			handler(val, oldVal) {
				if((val === true && oldVal === false) || val && !this.userData.isBanned) {
					this.fetchUsersData();
				}
			}
	  },
  },
  methods: {
    ...mapActions('users', ['fetchUsersData', 'setPage']),
    ...mapMutations('users', ['setFilters', 'unsetActiveFilter', 'setLastPage', 'setBackFromNewContacts', 'setUsersData']),
	  ...mapMutations('utils', ['setCurrentNav']),

    showProfileModal(username) {
			this.isShowProfileModal = true;
			this.profileUsername = username;
    },

    next(val) {
			this.setLastPage(val);
			this.$router.push({name: 'search', query: {page: val}}).catch(err => err)
      this.fetchUsersData({page: val});
    },

    changeNav(section) {
			this.setCurrentNav(section);
			this.setNavigationActive(section);
    },

	  setNavigationActive(section) {
		  Object.keys(this.nav).filter(el => {
			  this.nav[el] = section === el;
		  })
	  }
  },
  setup() {
    const currentPage = ref(1)

    return { currentPage }
  },

  mounted() {
		if(this.currentSearchNav) {
			this.setNavigationActive(this.currentSearchNav);
		}

  },
	beforeDestroy() {
		this.setUsersData([]);
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},

}
</script>