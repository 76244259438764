<template>
  <div :class="{'mt-3 lg:mt-4':index===0}">
    <a class="group flex flex-row gap-3 items-center text-xs cursor-pointer focus:outline-none">
      <!-- User image with new message dot -->
      <div class="flex flex-row items-start flex-none">
        <!-- User image -->
        <div @click="showProfileModal(rememberedUser.username)"
          class="flex-none relative"
        >
          <Picture
            :src="rememberedUser.featuredPictureUrl"
            classes="object-cover w-12 h-12 rounded-full
              transform ease-out duration-200 cursor-pointer border border-black
              lg:group-hover:scale-105 group-hover:border-2 group-hover:border-redAccent
            "
          />
          <ProfilePictureIcons
            :banned-flag = rememberedUser.isBanned
            :hidden-flag = rememberedUser.isHidden
          />
        </div>
      </div>
      <div class="w-full flex flex-row justify-between">
        <div class="flex flex-col w-full justify-between">
          <!-- Username -->
          <div  @click="showProfileModal(rememberedUser.username)"
            class="flex flex-row justify-between gap-2 items-start"
          >
            <span class="block whitespace-nowrap text-base leading-tight
              group-hover:text-redAccent group-hover:opacity-70 mb-1 whitespace-nowrap truncate"
              :class="getWidth('RememberedUserCard', 356)"
            >
             {{ rememberedUser.name }}
            </span>
            <VTooltip>
              <Button
                  variant="iconThreadAction"
                  @clicked="forgetUserAction(rememberedUser.username)"
              >
                <base-icon :width="16" :height="16" name="star">
                </base-icon>
              </Button>
              <template #popper>
                {{ trans('TOOLTIP:RemoveRememberedUser') }}
              </template>
            </VTooltip>
          </div>
          <span class="block text-s leading-tight text-grayedDark thread-span
            group-hover:text-redAccentLight cursor-pointer group-hover:opacity-70
             opacity-100"
          >
            {{ rememberedUser.gender.desc }}, {{ rememberedUser.age }}
          </span>
        </div>
      </div>
    </a>
    <Modal hide-footer variant="profile" v-if="isShowProfileModal" :show-modal="isShowProfileModal" @clicked="isShowProfileModal = false">
      <template #content>
        <ProfileGuest :username="profileUsername"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import {getWidth} from "@/common/helpers/utils";
import {mapActions} from "vuex";
import Modal from "@/utils/modal/Modal";
import ProfileGuest from "@/views/content/profile/ProfileGuest";
import ProfilePictureIcons from "@/views/content/components/ProfilePictureIcons";

export default {
  data() {
    return {
      getWidth: getWidth,
      isShowProfileModal: false,
      profileUsername: ''
    }
  },
  name: "RememberedUserCard",
  components: {
    BaseIcon,
    Button,
    Modal,
    ProfileGuest,
    ProfilePictureIcons
  },
  props: {
    rememberedUser: Object,
    index: Number,
    rememberedUsersLength: Number,
  },
  methods: {
    ...mapActions('profile', ['removeRememberedUserRequest']),

    showProfileModal(username) {
      this.isShowProfileModal = true;
      this.profileUsername = username;
    },
    forgetUserAction(username) {
      this.removeRememberedUserRequest({rememberedUsername: username, removeFromList: true})
    },
  }
}
</script>
