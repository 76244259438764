<template>
  <div class="flex-col w-full shrink-0">
    <FavouriteFilters v-show="showSidebarNav(currentNav.favourites)" />
    <SidebarSectionSeparatorLine  class="hidden lg:block"/>
    <Filters v-show="showSidebarNav(currentNav.filters)" />
    <SidebarSectionSeparatorLine class="hidden lg:block"/>
    <RememberedUsers v-show="showSidebarNav(currentNav.remembered)" />
  </div>
</template>

<script>
import FavouriteFilters from "@/views/content/filters/FavouriteFilters";
import Filters from "@/views/content/filters/Filters";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";
import RememberedUsers from "@/views/content/filters/RememberedUsers";
import SidebarSectionSeparatorLine from "@/views/content/components/SidebarSectionSeparatorLine"


export default {
  name: "SearchSidebar",
  components: {
    RememberedUsers,
    Filters,
    FavouriteFilters,
    SidebarSectionSeparatorLine
  },
  props: {
    currentNav: Object,
  },
  data() {
    return {

    }
  },
  computed: {

    isMobile() {
      return checkIsMobileDevice();
    }
  },
  methods: {

    showSidebarNav(val) {
      if(!this.isMobile) {
        return true;
      }
      return val;
    }
  },
  mounted() {
  },

}
</script>