<template>
  <div v-if="userData">
    <ListHeaderSection
      :is-favourite="true"
      :text="trans('LABEL:FavouriteFilters')"
      :count="userData.favouriteFilters.length"
      :total="Number(configData.userFiltersLimit)"
    >
<!--      @clicked="toggleFavouriteFilters"-->
      <base-icon :width="16" :height="16" name="funnel">
      </base-icon>
    </ListHeaderSection>
    <ul v-if="userData.favouriteFilters.length > 0">
      <transition-group name="slide">
        <li :key="filter.id" v-for="(filter, index) in favouriteFilters" v-show="areFavouriteFiltersShown">
          <FavouriteFilterCard :filter="filter" :index="index">
          </FavouriteFilterCard>
          <hr v-if="index !== favouriteFilters.length - 1" class="border-grayedHR my-2.5"/>
        </li>
      </transition-group>
    </ul>
    <ul v-else>
      <li class="text-grayed text-s mt-3 lg:mt-4">
        {{ trans('MESSAGE:PlaceForFavouriteFilters') }}
      </li>
    </ul>
  </div>
</template>

<script>
import IconLabel from "@/utils/IconLabel";
import BaseIcon from "@/utils/icons/BaseIcon";
import ListHeaderSection from "@/views/content/messages/components/ListHeaderSection";
import FavouriteFilterCard from "@/views/content/filters/components/FavouriteFilterCard";
import {mapGetters} from "vuex";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
export default {
  name: "FavouriteFilters",
  components: {FavouriteFilterCard, ListHeaderSection, BaseIcon, IconLabel},
  data() {
    return {
      favouriteFilters: [],
      areFavouriteFiltersShown: true,
    }
  },
  watch: {
    'userData.favouriteFilters': {
      immediate: true,
      handler(val){
        this.favouriteFilters = [];
        val.filter(el => {
          this.favouriteFilters.push({
            id: el.id,
            obj: fromQueryToObject(el.query),
            name: el.name
          })
        })
      }
    }
  },
  computed: {
    ...mapGetters('account', ['userData']),
    ...mapGetters('lang', ['configData'])
  },
  methods: {
    toggleFavouriteFilters() {
      this.areFavouriteFiltersShown = !this.areFavouriteFiltersShown
    }
  }
}
</script>
